import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  ButtonToolbar,
  OverlayTrigger,
  Table,
} from "react-bootstrap";

import { GammelMetode, NyMetode, NyFastledd } from "../models/beregning";

export default function Gebyr() {
  const [boenhet, setBoenhet] = useState(1);
  const [kvadrat, setKvadrat] = useState(120);

  const [vannmaler, setVannmaler] = useState(false);

  const [type, setType] = useState("Vann og avløp");

  const [endring, setEndring] = useState("billigere");

  const [stipDescActive, setStipDescActive] = useState(false);
  const [fastDescActive, setFastDescActive] = useState(false);

  const dagensForbruk = GammelMetode(vannmaler, kvadrat, type);
  const forslagForbruk = NyMetode(vannmaler, kvadrat, type);
  const dagensFastledd = 0;
  const forslagFastledd = NyFastledd(boenhet, type);

  return (
    <div className="row">
      <div className="col-12 forslag">
        <h1 style={{ color: "#184d65" }}>Derfor vil vi endre gebyret</h1>
        <p>
          Tromsø kommune ønsker å følge resten av landet å innføre en mer
          rettferdig fordeling av gebyret. Det er viktig å påpeke at en endring
          av dagens modell ikke skal medføre økte inntekter for oss. Dette er
          kun en omfordeling av spleiselaget. <br />
          <br />
          <b>Les mer om forslaget ved å klikke på boksene under.</b>
        </p>
        <div className="row p-2 d-flex justify-content-center flex-wrap">
          <div
            className="col-md-5 p-2 forslagCol m-2"
            style={{ height: "100%" }}
            onClick={() => setStipDescActive((prev) => !prev)}
          >
            <span style={{ fontSize: "18px" }}>
              {stipDescActive ? (
                <i className="ri-subtract-line" />
              ) : (
                <i className="ri-add-line" />
              )}
              for å redusere stipuleringsfaktoren fra 1,5 til 1,2
            </span>
            <div
              style={{
                borderTop: "0.2px #000 solid",
                marginTop: 5,
                padding: 3,
                display: stipDescActive ? "block" : "none",
              }}
            >
              <p className="info">
                <b>Hva menes med stipuleringsfaktor?</b>
                <br />
                For boligabonnenter som ikke har målt forbruk (vannmåler) vet vi
                ikke forbruket. Det betyr at kommunen må estimere dette. Dette
                kalles stipuleringsfaktor.{" "}
                <b>
                  Stipuleringsfaktoren i Tromsø er i dag 1,5 m3 per m2
                  bruksareal.
                </b>
              </p>
              <p className="info">
                <b>Hva bør stipuleringsfaktoren være?</b>
                <br />
                Ifølge Norsk Vann (2011) bør omregningsfaktoren for stipulert
                årlig forbruk ligge mellom 1,1–1,3 m3 per m2 bruksareal.{" "}
                <b>
                  I forslag til ny forskrift foreslås det en omregningsfaktor på
                  1,2 m3 per m2 bruksareal.
                </b>
              </p>
            </div>
          </div>
          <div
            className="col-md-5 p-2 forslagCol m-2"
            style={{ height: "100%" }}
            onClick={() => setFastDescActive((prev) => !prev)}
          >
            {" "}
            <span style={{ fontSize: "18px" }}>
              {fastDescActive ? (
                <i className="ri-subtract-line" />
              ) : (
                <i className="ri-add-line" />
              )}
              for å innføre fastledd
            </span>
            <div
              style={{
                borderTop: "0.2px #000 solid",
                marginTop: 5,
                padding: 3,
                display: fastDescActive ? "block" : "none",
              }}
            >
              <p className="info">
                I dagens ordning har ikke Tromsø kommune et
                fastledd/abonnementsgebyr for vann- og avløpstjenester. I
                forslaget til ny forskrift foreslås det at boligeiendom skal
                betale abonnementsgebyr som en fast sats per boenhet, mens
                fritidseiendom betaler per bruksenhet.
              </p>
              <p className="info">
                <ul>
                  <li>
                    Fastleddet skal dekke kommunens kostnader knyttet til den
                    teoretiske leveringskapaisteten til hver abonnentet
                  </li>
                  <li>
                    Kommunens infrastruktur avskrives lineært over 40 år slik at
                    de faste kostnadene er relativt stabile over tid og er
                    upåvirket av endringer i den enkelte abonnents varierende
                    vannforbruk.
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>

      <h1 style={{ color: "#184d65" }}>
        Her kan du regne ut hva dette betyr for deg
      </h1>

      <div className="col-12 gebyrberegning mb-4">
        <h4 className="text-dark py-2">Gebyrkalkulator for vann og avløp</h4>
        <i className="text-dark">
          Dataopplysninger i faktura hentes fra matrikkel. <br />
          Opplysninger til din bolig kan hentes fra{" "}
          <a href="https://seeiendom.no/" target="_blank">
            seeiendom.no
          </a>
        </i>
        <Table borderless>
          <tbody>
            <tr>
              <th>{vannmaler ? "Ant. kubikk" : "Bruksareal (BRA)"}</th>
              <td>
                <input
                  type="number"
                  defaultValue={kvadrat}
                  onChange={(e) => setKvadrat(e.target.value)}
                  style={{ borderRadius: 5, width: "100%" }}
                />
              </td>
            </tr>
            <tr>
              <th>Godkjente boenheter</th>
              <td>
                <input
                  type="number"
                  defaultValue={boenhet}
                  onChange={(e) => setBoenhet(e.target.value)}
                  style={{ borderRadius: 5, width: "100%" }}
                />
              </td>
            </tr>
            <tr>
              <th>Tilkobling</th>
              <td>
                <select
                  style={{ borderRadius: 5, width: "100%" }}
                  onChange={(e) => setType(e.target.value)}
                >
                  <option value="Vann og avløp">Vann og avløp</option>
                  <option value="Vann">Vann</option>
                  <option value="Avlop">Avløp</option>
                </select>
              </td>
            </tr>
          </tbody>
        </Table>
        <hr />

        {vannmaler && (
          <Alert variant="warning">
            <i className="ri-alert-line"></i> Installasjon av vannmåler kan
            koste mellom 15 til 20 tusen. I tillegg påløper målerleie.
          </Alert>
        )}

        <div className="table-responsive">
          <Table borderless>
            <thead style={{ borderBottom: "1px #000 solid" }}>
              <tr>
                <th colSpan={1}>Detaljer</th>
                <th>Dagens pris</th>
                <th>Ny pris</th>
                <th>Endring</th>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td
                  colSpan={3}
                  style={{ backgroundColor: "rgba(125, 125, 125, 0.1)" }}
                  className="text-center"
                >
                  <i>Priser inkl. mva. Basert på 2024.</i>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Forbruksledd</th>
                <td>
                  {dagensForbruk.toLocaleString("nb-no")}
                  kr
                </td>
                <td>
                  {forslagForbruk.toLocaleString("nb-no")}
                  kr
                </td>
                <td>
                  {parseInt(forslagForbruk - dagensForbruk).toLocaleString(
                    "nb-no"
                  )}
                  kr
                </td>
              </tr>
              <tr>
                <th>Abonnementsgebyr</th>
                <td>{dagensFastledd} kr</td>
                <td>{forslagFastledd.toLocaleString("nb-no")} kr</td>
                <td>
                  {parseInt(forslagFastledd - dagensFastledd).toLocaleString(
                    "nb-no"
                  )}
                  kr
                </td>
              </tr>
              <tr>
                <th className="sum">Sum årsgebyr</th>
                <th className="sum">
                  {parseInt(dagensFastledd + dagensForbruk).toLocaleString(
                    "nb-no"
                  )}{" "}
                  kr
                </th>
                <th className="sum">
                  {parseInt(forslagFastledd + forslagForbruk).toLocaleString(
                    "nb-no"
                  )}{" "}
                  kr
                </th>
                <th className="sum">
                  {parseInt(
                    parseInt(forslagFastledd + forslagForbruk) -
                      parseInt(dagensFastledd + dagensForbruk)
                  ).toLocaleString("nb-no")}
                  kr
                </th>
              </tr>
              <tr>
                <td>Pris pr. døgn</td>
                <td>
                  {parseInt(
                    parseInt(dagensFastledd + dagensForbruk) / 365
                  ).toLocaleString("nb-no")}{" "}
                  kr
                </td>
                <td>
                  {parseInt(
                    parseInt(forslagFastledd + forslagForbruk) / 365
                  ).toLocaleString("nb-no")}{" "}
                  kr
                </td>
                <td>
                  {parseInt(
                    parseInt(parseInt(forslagFastledd + forslagForbruk) / 365) -
                      parseInt(parseInt(dagensFastledd + dagensForbruk) / 365)
                  ).toLocaleString("nb-no")}{" "}
                  kr
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        <Button
          size="sm"
          onClick={() => setVannmaler((prev) => !prev)}
          variant="outline-dark"
          style={{ float: "right" }}
        >
          {vannmaler ? "Bytt til kvadrat" : "Jeg har vannmåler"}
        </Button>
        <i className="text-dark">Forbehold om feil</i>
      </div>

      {parseInt(
        parseInt(forslagFastledd + forslagForbruk) -
          parseInt(dagensFastledd + dagensForbruk)
      ) > 0 ? (
        <>
          <div className={`col-12 endring-billig`}>
            <h4>
              <i className="ri-drop-line"></i> {type} koster{" "}
              {parseInt(
                parseInt(forslagFastledd + forslagForbruk) / 365
              ).toLocaleString("nb-no")}{" "}
              kr per dag. Dette er noe av det du får:
            </h4>
          </div>
          <div className="col-12 bg-white py-2 mb-3">
            <div className="d-flex justify-content-center flex-column">
              <div className="d-flex flex-wrap align-items-center justify-content-center">
                <Button
                  variant="outline-dark"
                  style={{
                    pointerEvents: "none !important",
                    color: "black !important",
                    cursor: "default",
                  }}
                  className="p-2 m-2 noHover"
                >
                  Tilgang på vann og/eller avløp hele døgnet hele året
                </Button>
                <Button
                  variant="outline-dark"
                  style={{
                    pointerEvents: "none !important",
                    color: "black !important",
                    cursor: "default",
                  }}
                  className="p-2 m-2 noHover"
                >
                  Trygt, rent og godt drikkevann
                </Button>
                <Button
                  variant="outline-dark"
                  style={{
                    pointerEvents: "none !important",
                    color: "black !important",
                    cursor: "default",
                  }}
                  className="p-2 m-2 noHover"
                >
                  Brannvannsdekning/slokkevann
                </Button>
                <Button
                  variant="outline-dark"
                  style={{
                    pointerEvents: "none !important",
                    color: "black !important",
                    cursor: "default",
                  }}
                  className="p-2 m-2 noHover"
                >
                  Vakttjeneste og beredskap
                </Button>
                <Button
                  variant="outline-dark"
                  style={{
                    pointerEvents: "none !important",
                    color: "black !important",
                    cursor: "default",
                  }}
                  className="p-2 m-2 noHover"
                >
                  Varslingssystem
                </Button>
                <Button
                  variant="outline-dark"
                  style={{
                    pointerEvents: "none !important",
                    color: "black !important",
                    cursor: "default",
                  }}
                  className="p-2 m-2 noHover"
                >
                  Rørfornying og utbygging
                </Button>
                <Button
                  variant="outline-dark"
                  style={{
                    pointerEvents: "none !important",
                    color: "black !important",
                    cursor: "default",
                  }}
                  className="p-2 m-2 noHover"
                >
                  Renset avløpsvann
                </Button>
              </div>
            </div>
          </div>
          <div className="col-12 bg-white py-2">
            <p>
              <b>
                Boliger med lavt bruksareal (BRA) eller boliger med vannmåler
                vil få noe høyere vann- og avløpsgebyr.
              </b>{" "}
              <br />
              Dette skyldes at vi mener større boliger betaler for mer enn
              småforbrukere når det gjelder finansiering av kapitalkostnader
              (avskrivinger og renter).
            </p>
            <p>
              For abonnenter med vannmåler vil alle få et høyere gebyr på grunn
              av innføring av fastledd.
            </p>
            <p>
              Produksjon av vann er i utgangspunktet relativt billig i Tromsø.
              Det som er kostbart er å få levert og fraktet vann og avløp til og
              fra abonnentene. Det er nettopp denne kostnaden vi ønsker å
              fordele bedre med innføring av fastleddet.
            </p>
            <hr />
            <h5>Hvordan beregnes vann- og avløpsgebyret?</h5>
            <p>
              Vann og avløp er underlagt selvkostprinsippet. Det betyr at vi
              ikke skal tjene penger på tjenesten vi leverer.
            </p>
            <p>
              Våre kostnader består av driftskostnader (lønn, kjøp av varere og
              tjenester m.m.), samt rentekostnader og avskrivinger. <br />
              Rentekostnadene har siden 2020 økt fra 24,3 millioner kroner til
              77,7 millioner kroner i 2023. Dette er en økning på 320 % på 3 år,
              og i 2024 er det budsjettert med en rentekostnad på 79,3 millioner
              kroner. Kapitalkostnadene (renter og avskrivinger) utgjør for
              tiden ca. 50 % av det totale vann- og avløpsbudsjettet.
            </p>
            <p>
              Dersom vann og avløp går med overskudd, avsettes overskuddet til
              et selvkostfond. Overskuddet skal brukes til å finansiere et
              fremtidig underskudd hos vann og avløp i løpet av de neste fem
              årene.
            </p>
          </div>
        </>
      ) : (
        <>
          <div className={`col-12 endring-billig`}>
            <h4>
              <i className="ri-drop-line"></i> {type} koster{" "}
              {parseInt(
                parseInt(forslagFastledd + forslagForbruk) / 365
              ).toLocaleString("nb-no")}{" "}
              kr per dag. Dette er noe av det du får:
            </h4>
          </div>
          <div className="col-12 bg-white py-2 mb-3">
            <div className="d-flex justify-content-center flex-column">
              <div className="d-flex flex-wrap align-items-center justify-content-center">
                <Button
                  variant="outline-dark"
                  style={{
                    pointerEvents: "none !important",
                    color: "black !important",
                    cursor: "default",
                  }}
                  className="p-2 m-2 noHover"
                >
                  Tilgang på vann og/eller avløp hele døgnet hele året
                </Button>
                <Button
                  variant="outline-dark"
                  style={{
                    pointerEvents: "none !important",
                    color: "black !important",
                    cursor: "default",
                  }}
                  className="p-2 m-2 noHover"
                >
                  Trygt, rent og godt drikkevann
                </Button>
                <Button
                  variant="outline-dark"
                  style={{
                    pointerEvents: "none !important",
                    color: "black !important",
                    cursor: "default",
                  }}
                  className="p-2 m-2 noHover"
                >
                  Brannvannsdekning/slokkevann
                </Button>
                <Button
                  variant="outline-dark"
                  style={{
                    pointerEvents: "none !important",
                    color: "black !important",
                    cursor: "default",
                  }}
                  className="p-2 m-2 noHover"
                >
                  Vakttjeneste og beredskap
                </Button>
                <Button
                  variant="outline-dark"
                  style={{
                    pointerEvents: "none !important",
                    color: "black !important",
                    cursor: "default",
                  }}
                  className="p-2 m-2 noHover"
                >
                  Varslingssystem
                </Button>
                <Button
                  variant="outline-dark"
                  style={{
                    pointerEvents: "none !important",
                    color: "black !important",
                    cursor: "default",
                  }}
                  className="p-2 m-2 noHover"
                >
                  Rørfornying og utbygging
                </Button>
                <Button
                  variant="outline-dark"
                  style={{
                    pointerEvents: "none !important",
                    color: "black !important",
                    cursor: "default",
                  }}
                  className="p-2 m-2 noHover"
                >
                  Renset avløpsvann
                </Button>
              </div>
            </div>
          </div>
          <div className="col-12 bg-white py-2">
            <p>
              <b>
                Boliger med normalt eller høyt bruksareal (BRA) og som
                faktureres på bakgrunn av stipulert forbruk vil få noe lavere
                vann- og avløpsgebyr.
              </b>{" "}
              <br />
              Dette skyldes at vi mener at storforbrukere (stipulert) bidrar mer
              enn småforbrukere når det gjelder finansiering av kapitalkostnader
              (avskrivinger og renter)
            </p>
            <hr />
            <h5>Hvordan beregnes vann- og avløpsgebyret?</h5>
            <p>
              Vann og avløp er underlagt selvkostprinsippet. Det betyr at vi
              ikke skal tjene penger på tjenesten vi leverer.
            </p>
            <p>
              Våre kostnader består av driftskostnader (lønn, kjøp av varere og
              tjenester m.m.), samt rentekostnader og avskrivinger. <br />
              Rentekostnadene har siden 2020 økt fra 24,3 millioner kroner til
              77,7 millioner kroner i 2023. Dette er en økning på 320 % på 3 år,
              og i 2024 er det budsjettert med en rentekostnad på 79,3 millioner
              kroner. Kapitalkostnadene (renter og avskrivinger) utgjør for
              tiden ca. 50 % av det totale vann- og avløpsbudsjettet.
            </p>
            <p>
              Dersom vann og avløp går med overskudd, avsettes overskuddet til
              et selvkostfond. Overskuddet skal brukes til å finansiere et
              fremtidig underskudd hos vann og avløp i løpet av de neste fem
              årene.
            </p>
          </div>
        </>
      )}
      <div className="col-12 my-2">
        <div className="d-flex flex-wrap">
          <div className="p-2">
            <a
              target="_blank"
              href="https://innsyn.tromso.kommune.no/application/getMoteDokument?dokid=2001796991"
            >
              <Button>Forslag om gebyrforskrift</Button>
            </a>
          </div>
          <div className="p-2">
            <a
              href="https://innsyn.tromso.kommune.no/application/getMoteDokument?dokid=2001796992"
              target="_blank"
            >
              <Button>Høring</Button>
            </a>
          </div>
          <div className="p-2">
            <a
              href="https://innsyn.tromso.kommune.no/application/getMoteDokument?dokid=2001796994"
              target="_blank"
            >
              <Button>Kommentarutgave</Button>
            </a>
          </div>
        </div>
      </div>
      <div className="col-12 my-2">
        <h4>Kontaktinformasjon til vann og avløp</h4>
        <p>vannpost@tromso.kommune.no</p>
      </div>
    </div>
  );
}
