import React, { useEffect, useState, useMemo } from "react";

const GammelMetode = (harMålt, input, type) => {
  const stipulertPrisVann = 35.04;
  const stipulertPrisAvlop = 41.55;

  const kubikkprisVann = 23.36;
  const kubikkprisAvlop = 27.7;

  const pris = useMemo(() => {
    const parsedInput = parseInt(input);

    if (harMålt) {
      if (type === "Vann og avløp") {
        const vannPris = kubikkprisVann * parsedInput;
        const avlopPris = kubikkprisAvlop * parsedInput;
        return vannPris + avlopPris;
      }
      if (type === "Vann") {
        return kubikkprisVann * parsedInput;
      }
      if (type === "Avlop") {
        return kubikkprisAvlop * parsedInput;
      }
    } else {
      if (type === "Vann og avløp") {
        const vannPris = stipulertPrisVann * parsedInput;
        const avlopPris = stipulertPrisAvlop * parsedInput;
        return vannPris + avlopPris;
      }
      if (type === "Vann") {
        return stipulertPrisVann * parsedInput;
      }
      if (type === "Avlop") {
        return stipulertPrisAvlop * parsedInput;
      }
    }

    return 0; // Tilfelle hvor input eller type ikke stemmer
  }, [
    harMålt,
    input,
    type,
    kubikkprisVann,
    kubikkprisAvlop,
    stipulertPrisVann,
    stipulertPrisAvlop,
  ]);

  return Math.round(pris); // Returnerer pris, avrundet hvis ønskelig
};

const NyMetode = (harMålt, input, type) => {
  const stipulertPrisVann = 31.06;
  const stipulertPrisAvlop = 35.71;

  const kubikkprisVann = 25.88;
  const kubikkprisAvlop = 29.76;

  const pris = useMemo(() => {
    const parsedInput = parseInt(input);

    if (harMålt) {
      if (type === "Vann og avløp") {
        const vannPris = kubikkprisVann * parsedInput;
        const avlopPris = kubikkprisAvlop * parsedInput;
        return vannPris + avlopPris;
      }
      if (type === "Vann") {
        return kubikkprisVann * parsedInput;
      }
      if (type === "Avlop") {
        return kubikkprisAvlop * parsedInput;
      }
    } else {
      if (type === "Vann og avløp") {
        const vannPris = stipulertPrisVann * parsedInput;
        const avlopPris = stipulertPrisAvlop * parsedInput;
        return vannPris + avlopPris;
      }
      if (type === "Vann") {
        return stipulertPrisVann * parsedInput;
      }
      if (type === "Avlop") {
        return stipulertPrisAvlop * parsedInput;
      }
    }

    return 0; // Tilfelle hvor input eller type ikke stemmer
  }, [
    harMålt,
    input,
    type,
    kubikkprisVann,
    kubikkprisAvlop,
    stipulertPrisVann,
    stipulertPrisAvlop,
  ]);

  return Math.round(pris); // Returnerer pris, avrundet hvis ønskelig
};

const NyFastledd = (input, type) => {
  const fastleddVann = 470;
  const fastleddAvlop = 528;

  const pris = useMemo(() => {
    const parsedInput = parseInt(input);

    if (type === "Vann og avløp") {
      const vannPris = fastleddVann * parsedInput;
      const avlopPris = fastleddAvlop * parsedInput;
      return vannPris + avlopPris;
    }
    if (type === "Vann") {
      return fastleddVann * parsedInput;
    }
    if (type === "Avlop") {
      return fastleddAvlop * parsedInput;
    }

    return 0; // Tilfelle hvor input eller type ikke stemmer
  }, [input, type, fastleddVann, fastleddAvlop]);

  return Math.round(pris); // Returnerer pris, avrundet hvis ønskelig
};

export { GammelMetode, NyMetode, NyFastledd };
