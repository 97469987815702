import React from "react";

export default function Template({ children }) {
  return (
    <div className="container-fluid">
      <div className="container">
        <header>
          <center>
            <img
              className="img-fluid"
              src="https://tromso.kommune.no/themes/custom/tinden/favicon.ico"
            />
            <h1>Forslag til ny vann- og avløpsforskrift</h1>
            <a
              href="https://innsyn.tromso.kommune.no/motekalender/motedag/200858010"
              target="_blank"
              style={{ textDecoration: "none", color: "black" }}
            >
              <span>
                Behandles i kommunestyret 11. september, sak 0122/24.{" "}
              </span>
            </a>
          </center>
        </header>
      </div>
      <div className="container">{children}</div>
    </div>
  );
}
